import React from "react";

export default function Header() {
  return (
    <div className="header">
      <h1 className="header-title">Nawa Kyōhon</h1>
      <nav className="header-nav">
        {/* <ul>
          <li>
            <a href="../">Instructionals</a>
          </li>
          <li>
            <a href="/glossary">Glossary</a>
          </li>
          <li>
            <a href="/notation">Notation</a>
          </li>
        </ul> */}
      </nav>
    </div>
  );
}
